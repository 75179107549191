import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

import Spinner from "./Spinner";

function Button({
  children,
  action,
  submit = false,
  externalUrl = null,
  anchor = null,
  variant = "primary",
  size = "base",
  className = "",
  disabled = false,
  loading = false,
  loadingLabel = null,
}) {
  return (
    <>
      {anchor || externalUrl ? (
        <a
          href={anchor || externalUrl}
          target={externalUrl && "_blank"}
          rel={externalUrl && "noreferrer"}
          className={cx(
            "font-dm-sans uppercase font-medium text-xl py-2 px-5 border-2 cursor-pointer whitespace-nowrap h-max transition-colors",
            variant === "primary" &&
              "text-white bg-black border-black hover:text-black hover:bg-limestone",
            variant === "secondary" &&
              "text-black bg-transparent border-black hover:text-white hover:bg-black",
            variant === "ghost" &&
              "text-white border-white hover:text-white hover:bg-alpha-white-20",
            size === "xs" && "text-xs py-1 px-2",
            size === "sm" && "text-sm py-1 px-2",
            size === "base" && "text-base py-2 px-3",
            size === "lg" && "text-xl py-2 px-5",
            loading && "flex items-center",
            (disabled || loading) &&
              "bg-grey-medium border-grey-medium text-white pointer-events-none",
            className,
          )}
        >
          {loading ? (
            <>
              <Spinner className="animate-spin mr-4 h-6 w-6 text-white" />
              {loadingLabel || "Loading"}
            </>
          ) : (
            children
          )}
        </a>
      ) : (
        <button
          onClick={action}
          type={submit ? "submit" : "button"}
          disabled={disabled}
          className={cx(
            "font-dm-sans uppercase font-medium border-2 cursor-pointer whitespace-nowrap antialiased h-max transition-colors",
            variant === "primary" &&
              "text-white bg-black border-black hover:text-black hover:bg-limestone",
            variant === "secondary" &&
              "text-black bg-transparent border-black hover:text-white hover:bg-black",
            size === "xs" && "text-xs py-1 px-2",
            size === "sm" && "text-sm py-1 px-2",
            size === "base" && "text-base py-2 px-3 leading-snug",
            size === "lg" && "text-xl py-2 px-5",
            loading && "flex items-center",
            (disabled || loading) &&
              "bg-grey-medium border-grey-medium text-white pointer-events-none",
            className,
          )}
        >
          {loading ? (
            <>
              <Spinner className="animate-spin mr-4 h-6 w-6 text-white" />
              {loadingLabel || "Loading"}
            </>
          ) : (
            children
          )}
        </button>
      )}
    </>
  );
}

export default Button;

Button.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  action: PropTypes.func,
  submit: PropTypes.bool,
  externalUrl: PropTypes.string,
  anchor: PropTypes.string,
  variant: PropTypes.oneOf(["primary", "secondary", "ghost"]),
  size: PropTypes.oneOf(["xs", "sm", "base", "lg"]),
  className: PropTypes.string,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  loadingLabel: PropTypes.string,
};
