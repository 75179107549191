import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

function Label({
  children,
  size = "sm",
  colour = "black",
  filled = false,
  bold = false,
  uppercase = true,
  className,
}) {
  return (
    <label
      className={cx(
        "font-dm-sans antialiased",
        size === "xs" && "text-xs",
        size === "sm" && "text-xs md:text-sm",
        size === "base" && "text-sm md:text-base",
        size === "lg" && "text-base md:text-xl",
        bold ? "font-bold" : "font-medium",
        uppercase && "uppercase",
        filled &&
          "h-6 pr-2 pl-3 py-[3px] md:h-8 md:pr-4 md:pl-5 md:py-1 font-dm-sans text-xs md:text-sm leading-snug md:leading-normal border-1 border-limestone rounded-t-full rounded-br-full whitespace-nowrap bg-limestone font-bold",
        `text-${colour}`,
        className && className,
      )}
    >
      {children}
    </label>
  );
}

export default Label;

Label.propTypes = {
  children: PropTypes.node,
  size: PropTypes.oneOf(["xs", "sm", "base", "lg"]),
  colour: PropTypes.string,
  filled: PropTypes.bool,
  bold: PropTypes.bool,
  uppercase: PropTypes.bool,
  className: PropTypes.string,
};
