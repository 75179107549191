import React, { useState, useEffect, useContext } from "react";
import app from "gatsby-plugin-firebase-v9.0";

import { AuthContext } from "../context/authContext";

import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  signOut,
} from "firebase/auth";

import { doc, setDoc, getFirestore } from "firebase/firestore";

import Heading from "../components/common/Heading";
import Label from "../components/common/Label";
import Button from "../components/common/Button";
import CustomHead from "../components/common/Head";

const Register = () => {
  const [data, setData] = useState({
    email: "",
    password: "",
    error: null,
  });
  const [hasRegistered, setHasRegistered] = useState(false);
  const [hasLoggedIn, setHasLoggedIn] = useState(false);

  const { user, setUser } = useContext(AuthContext);

  const auth = getAuth(app);
  const database = getFirestore(app);

  useEffect(() => {
    const loginUser = async () => {
      try {
        const loginResponse = await signInWithEmailAndPassword(
          auth,
          user.email,
          data.password,
        );

        setHasLoggedIn(true);
        console.log("loginResponse", loginResponse);
      } catch (err) {
        console.log("LOG IN ERROR", err);
        setHasLoggedIn(false);
      }
      console.log("USER AFTER LOGIN", user);
    };

    if (hasRegistered && user && user.uid) {
      console.log("CALLING LOG IN");
      loginUser();
    }
  }, [hasRegistered, user, database, auth, data]);

  useEffect(() => {
    const writeNewUserToDb = async () => {
      try {
        setDoc(doc(database, "users", user.uid), {
          email: user.email,
          name: "Joeyyy",
        });
      } catch (err) {
        console.log("DB ERROR", err);
      }
    };

    if (hasLoggedIn && user && user.uid) {
      console.log("CALLING WRITETODB");
      writeNewUserToDb();
    }
  }, [hasLoggedIn, user, database]);

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setData({ ...data, error: null });

    try {
      const result = await createUserWithEmailAndPassword(
        auth,
        data.email,
        data.password,
      );
      setUser(result.user);
      setHasRegistered(true);
    } catch (err) {
      console.log("REG ERROR", err);
      setData({ ...data, error: err.message });
      setHasRegistered(false);
    }
  };

  return (
    <form>
      <Heading className="mb-4">Register</Heading>
      <fieldset className="flex gap-2 mb-4">
        <Label>Email</Label>
        <input
          type="text"
          name="email"
          value={data.email}
          onChange={handleChange}
          className="text-xs py-1 px-2 border-2 border-black mr-4 sm:w-72"
        />
      </fieldset>
      <fieldset className="flex gap-2 mb-4">
        <Label>Password</Label>
        <input
          type="password"
          name="password"
          value={data.password}
          onChange={handleChange}
          className="text-xs py-1 px-2 border-2 border-black mr-4 sm:w-72"
        />
      </fieldset>

      {data.error && <p className="text-apricot text-xs mb-8">{data.error}</p>}

      <Button type="submit" onClick={handleSubmit}>
        Register
      </Button>
    </form>
  );
};

export const Head = () => {
  return <CustomHead noIndex />;
};

export default Register;
