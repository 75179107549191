import React from "react";
import cx from "classnames";

function Heading({
  children,
  level,
  colour = "black",
  uppercase = false,
  bold = false,
  withLineBefore = false,
  withLineAfter = false,
  className,
}) {
  switch (level) {
    case "super":
      return (
        <h1
          className={cx(
            "font-dm-sans font-bold antialiased text-4xl sm:text-6xl lg:text-7xl leading-tight",
            `text-${colour}`,
            uppercase && "uppercase",
            className && className,
          )}
        >
          {children}
        </h1>
      );
    case "h1":
      return (
        <h1
          className={cx(
            "font-dm-sans font-bold antialiased text-3xl sm:text-5xl lg:text-6xl leading-tight",
            `text-${colour}`,
            uppercase && "uppercase",
            className && className,
          )}
        >
          {children}
        </h1>
      );
    case "h2":
      return (
        <div
          className={cx(
            (withLineAfter || withLineBefore) &&
              "flex md:items-end md:justify-end",
          )}
        >
          {withLineBefore && (
            <hr className="hidden md:inline-block w-full mb-2" />
          )}
          <h2
            className={cx(
              "font-dm-sans font-medium antialiased text-3xl md:text-4xl xl:text-5xl",
              `text-${colour}`,
              withLineBefore
                ? "md:leading-none md:whitespace-nowrap"
                : "leading-snug",
              uppercase && "uppercase",
              className && className,
            )}
          >
            {children}
          </h2>
        </div>
      );
    case "h3":
      return (
        <div
          className={cx(
            (withLineAfter || withLineBefore) &&
              "flex md:items-end md:justify-end",
          )}
        >
          {withLineBefore && (
            <hr className="hidden md:inline-block w-full mb-2" />
          )}
          <h3
            className={cx(
              "font-dm-sans font-medium antialiased text-[30px] sm:text-3xl lg:text-4xl",
              `text-${colour}`,
              withLineAfter || withLineBefore
                ? "leading-none whitespace-nowrap"
                : "leading-snug",
              withLineBefore && "ml-10",
              uppercase && "uppercase",
              className && className,
            )}
          >
            {children}
          </h3>
          {withLineAfter && (
            <hr className="hidden md:inline-block w-full mb-2" />
          )}
        </div>
      );
    case "h4":
      return (
        <h4
          className={cx(
            "font-dm-serif font-medium antialiased text-3xl md:text-4xl lg:text-5xl xl:text-6xl leading-tight",
            `text-${colour}`,
            className && className,
          )}
        >
          {children}
        </h4>
      );
    case "h5":
      return (
        <h5
          className={cx(
            "font-dm-serif font-medium antialiased text-2xl lg:text-3xl leading-snug",
            `text-${colour}`,
            className && className,
          )}
        >
          {children}
        </h5>
      );
    default:
    case "h6":
      return (
        <div className={cx(withLineAfter && "flex items-end justify-end")}>
          <h6
            className={cx(
              "font-dm-sans antialiased text-base md:text-xl lg:text-2xl",
              `text-${colour}`,
              uppercase && "uppercase",
              bold ? "font-bold" : "font-medium",
              withLineAfter
                ? "md:leading-none md:whitespace-nowrap mr-2 md:mr-6"
                : "leading-snug",
              className && className,
            )}
          >
            {children}
          </h6>
          {withLineAfter && <hr className="inline-block w-full mb-1" />}
        </div>
      );
  }
}

export default Heading;
